import React from "react";
import { Formik, Form, Field } from "formik";
import { send } from '@emailjs/browser';
import ContactFromData from "../../data/sections/form-info.json";
import { useTranslation } from 'react-i18next';

const ContactForm = () => {

    const { t } = useTranslation();

    const messageRef = React.useRef(null);

    function validateEmail(value) {
        let error;
        if (!value) {
            error = t('contact.form.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = t('contact.form.invalidEmail');
        }
        return error;
    }

    const sendMessage = (values) => {
        return send('service_wcqacrw', 'template_jiy6cg6', values, 'D5-CqJLJ3SRlaSH2B');
    }

    return (
        <section className="contact section-padding">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6">
                        <div className="form md-mb50">
                            <h4 className="fw-700 color-font mb-50">
                                { t('contact.form.title') }
                            </h4>

                            <Formik
                                initialValues={ {
                                    name: '',
                                    email: '',
                                    message: ''
                                } }
                                onSubmit={ async (values) => {
                                    try {
                                        await sendMessage(values);
                                    } catch (err) {
                                        console.error(err);
                                        messageRef.current.innerText = t('contact.form.error');
                                    }

                                    messageRef.current.innerText = t('contact.form.success');

                                    values.name = '';
                                    values.email = '';
                                    values.message = '';

                                    setTimeout(() => {
                                        messageRef.current.innerText = '';
                                    }, 5000);
                                } }
                            >
                                { ({ errors, touched }) => (
                                    <Form id="contact-form">

                                        <div className="messages" ref={ messageRef }></div>

                                        <div className="controls">
                                            <div className="form-group">
                                                <Field
                                                    id="form_name"
                                                    type="text"
                                                    name="name"
                                                    placeholder={ t('contact.form.name') }
                                                    required="required"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <Field
                                                    validate={ validateEmail }
                                                    id="form_email"
                                                    type="email"
                                                    name="email"
                                                    placeholder={ t('contact.form.email') }
                                                />
                                                { errors.email && touched.email && (
                                                    <div>{ errors.email }</div>
                                                ) }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <Field
                                                as="textarea"
                                                id="form_message"
                                                name="message"
                                                placeholder={ t('contact.form.message') }
                                                style={ { color: 'black' } }
                                                rows="4"
                                                required="required"
                                            />
                                        </div>

                                        <button type="submit" className="butn bord">
                                            <span>{ t('contact.form.send') }</span>
                                        </button>

                                    </Form>
                                ) }
                            </Formik>
                        </div>
                    </div>

                    <div className="col-lg-5 offset-lg-1">
                        <div className="cont-info">
                            <h4 className="fw-700 color-font mb-50">
                                { t('contact.form.contactInfo') }
                            </h4>

                            <h3 className="wow" data-splitting>
                                { t('contact.form.letsTalk') }
                            </h3>

                            <div className="item mb-40">
                                <h5>
                                    <a href="#">{ ContactFromData.email }</a>
                                </h5>
                                <h5>{ ContactFromData.phone }</h5>
                            </div>

                            <h3 className="wow" data-splitting>
                                { t('contact.form.visitUs') }
                            </h3>

                            <div className="item">
                                <p>
                                    { t('contact.form.location.first') }
                                </p>
                                <p>
                                    { t('contact.form.location.second') }
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default ContactForm;
